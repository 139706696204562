import React from 'react'
import styled from 'styled-components'

const Content = styled.footer`
  color: ${props => props.theme.colors.secondary};
  text-align: center;
  font-size: 0.9rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: ${props => props.theme.colors.bg};
`

const Footer = () => (
  <Content>
    &copy; 2018 by Leonard LEPADATU. Design by
    <a href="https://redpm.org" target="_blank" rel="noopener noreferrer">
      RedPM Organization
    </a>
  </Content>
)

export default Footer
